import React from 'react'
import BlogTemplate from 'templates/blog-template'
import { graphql } from 'gatsby'

const Blog = props => {
  return <BlogTemplate header='Blog' {...props} />
}

export const pageQuery = graphql`
  query blog {
    allSanityBlogPost(
      sort: { fields: publishedDate, order: DESC }
      filter: { publishedDate: { ne: null } }
    ) {
      nodes {
        authors
        category {
          slug
          titlePlural
        }
        id
        featuredImage {
          asset {
            gatsbyImageData(width: 750)
          }
        }
        slug
        title
      }
    }
  }
`

export default Blog
